<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
  name: "HowToBuy",
})
</script>

<template>
  <div class="container">
  <div class="page-titles">How to Purchase DECE Token </div>
  <div class="center">
    <iframe
        src="https://www.youtube.com/embed/WcwsFcpd9Hk">
    </iframe>
  </div>
  </div>

</template>

<style scoped lang="css">
iframe {
  border: 0;
  width: 350px;
  height: 600px;
}
</style>